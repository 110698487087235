import {
    GET_VIDEO_REQUEST,
    GET_VIDEO_SUCCESS,
    GET_VIDEO_FAIL,

    VIDEO_CLICKS_REQUEST,
    VIDEO_CLICKS_SUCCESS,
    VIDEO_CLICKS_FAIL,

    VIDEOS_FOR_BANNER_REQUEST,
    VIDEOS_FOR_BANNER_SUCCESS,
    VIDEOS_FOR_BANNER_FAIL,

    GET_VIDEOS_FOR_ADMIN_REQUEST,
    GET_VIDEOS_FOR_ADMIN_SUCCESS,
    GET_VIDEOS_FOR_ADMIN_FAIL,

    FILTER_RESULTS_FOR_ADMIN_REQUEST,
    FILTER_RESULTS_FOR_ADMIN_SUCCESS,
    FILTER_RESULTS_FOR_ADMIN_FAIL,
    
    SEARCH_RESULTS_FOR_ADMIN_REQUEST,
    SEARCH_RESULTS_FOR_ADMIN_SUCCESS,
    SEARCH_RESULTS_FOR_ADMIN_FAIL,

    GET_VIDEOS_LENGTH_REQUEST,
    GET_VIDEOS_LENGTH_SUCCESS,
    GET_VIDEOS_LENGTH_FAIL,

    VIDEOS_LENGTH_FOR_FILTER_REQUEST,
    VIDEOS_LENGTH_FOR_FILTER_SUCCESS,
    VIDEOS_LENGTH_FOR_FILTER_FAIL,

    VIDEOS_LENGTH_FOR_SEARCH_REQUEST,
    VIDEOS_LENGTH_FOR_SEARCH_SUCCESS,
    VIDEOS_LENGTH_FOR_SEARCH_FAIL,

    UPLOAD_VIDEO_REQUEST,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAIL,

    ADD_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST,
    ADD_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS,
    ADD_PLAYLIST_VIDEO_FOR_ADMIN_FAIL,
    
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_REQUEST,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_SUCCESS,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_FAIL,

    ADD_VIDEO_REQUEST,
    ADD_VIDEO_SUCCESS,
    ADD_VIDEO_FAIL,

    GET_VIDEO_OFF_REQUEST,
    GET_VIDEO_OFF_SUCCESS,
    GET_VIDEO_OFF_FAIL,

    GET_VIDEO_OFF_LENGTH_REQUEST,
    GET_VIDEO_OFF_LENGTH_SUCCESS,
    GET_VIDEO_OFF_LENGTH_FAIL,

    DELETE_VIDEO_REQUEST,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAIL,

    GET_VIDEO_INFO_REQUEST,
    GET_VIDEO_INFO_SUCCESS,
    GET_VIDEO_INFO_FAIL,

    EDIT_VIDEO_REQUEST,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAIL,

} from '../constants/videoConstants'

export const activeVideoReducer = (state = { activeVideo:{} }, action) => {
    switch (action.type){
      case GET_VIDEO_REQUEST:
        return { loading: true, ...state}
  
      case GET_VIDEO_SUCCESS:
        return { loading: false, activeVideo: action.payload }
  
      case GET_VIDEO_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }


  export const videosForBannerReducer = (state = { videosForBanner:{} }, action) => {
    switch (action.type){
      case VIDEOS_FOR_BANNER_REQUEST:
        return { loading: true,    videosForBanner : {}}
  
      case VIDEOS_FOR_BANNER_SUCCESS:
        return { loading: false, videosForBanner: action.payload }
  
      case VIDEOS_FOR_BANNER_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }


  export const videoClicksReducer = (state = { videoVlicks:'' }, action) => {
    switch (action.type){
      case VIDEO_CLICKS_REQUEST:
        return { loading: true,    videoVlicks : ''}
  
      case VIDEO_CLICKS_SUCCESS:
        return { loading: false, videoVlicks: action.payload }
  
      case VIDEO_CLICKS_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }


  const initialState = {
    adminVideos: [],
    uploadedVideo : {},
    videoLoading: false,
    index: null,
    adminVideosLoading: false,
    videosLength: null
  }

  export const adminVideosReducer = (state = initialState, action) => {
    switch (action.type){


      case GET_VIDEOS_LENGTH_REQUEST:
        return { 
          adminVideosLoading: true,
          ...state,
        }
  
      case GET_VIDEOS_LENGTH_SUCCESS:
        return { 
          ...state,
          videosLength: action.payload
        }
  
      case GET_VIDEOS_LENGTH_FAIL:
        return { 
          adminVideosLoading: false,
          ...state
        }

      case GET_VIDEO_OFF_LENGTH_REQUEST:
        return { 
          adminVideosLoading: true,
          ...state,
        }
  
      case GET_VIDEO_OFF_LENGTH_SUCCESS:
        return { 
          ...state,
          videosLength: action.payload
        }
  
      case GET_VIDEO_OFF_LENGTH_FAIL:
        return { 
          adminVideosLoading: false,
          ...state
        }


      case GET_VIDEOS_FOR_ADMIN_REQUEST:
        return { 
          ...state,
          adminVideosLoading: true,
          index: action.index
        }
  
      case GET_VIDEOS_FOR_ADMIN_SUCCESS:
        return { 
          ...state,
          adminVideosLoading: false, 
          adminVideos: action.payload,
          index: action.index
        }


        case DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_REQUEST:
          return { 
            ...state 
          }
    
        case DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_SUCCESS:
          let vs = state.adminVideos.find(v=>v.id===action.video_id)
          vs.playlists=vs.playlists.filter(playlist=>playlist!==action.playlist_id)
          return { 
            ...state 
          }
    
        case DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FROM_VIDEOS_PAGE_FAIL:
          return { 
            ...state 
          }

          case UPLOAD_VIDEO_REQUEST:
            return { 
              ...state,
              videoLoading: true, 
              uploadedVideo : {},
            }
      
          case UPLOAD_VIDEO_SUCCESS:
            return { 
              ...state,
              videoLoading: false, 
              uploadedVideo: action.payload,
             }
      
          case UPLOAD_VIDEO_FAIL:
            return { 
              ...state,
              videoLoading: false, 
              error: action.payload,
             }    


        case ADD_VIDEO_REQUEST:
          return { 
            ...state 
          }
    
        case ADD_VIDEO_SUCCESS:
          return { 
            adminVideos: [action.payload, ...state.adminVideos],
            ...state,
            uploadedVideo: {},
          }
    
        case ADD_VIDEO_FAIL:
          return { 
            ...state,
            uploadedVideo: {},
          }

        case DELETE_VIDEO_REQUEST:
          return { 
            ...state 
          }
    
        case DELETE_VIDEO_SUCCESS:
          return { 
            ...state,
            adminVideos: state.adminVideos.filter(video=>video.id!==action.video_id),
          }
    
        case DELETE_VIDEO_FAIL:
          return { 
            ...state 
          }

          case EDIT_VIDEO_REQUEST:
            return { 
              ...state 
            }
      
          case EDIT_VIDEO_SUCCESS:
            return { 
              ...state,
            }
      
          case EDIT_VIDEO_FAIL:
            return { 
              ...state 
            }

        case ADD_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST:
          return { 
            ...state 
          }
    
        case ADD_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS:
          let vds = state.adminVideos.filter(vd=>action.video_list.includes(vd.id))
          vds.forEach(video => {
            video.playlists.push(action.playlist.id)
          });
          return { 
            ...state 
          }
    
        case ADD_PLAYLIST_VIDEO_FOR_ADMIN_FAIL:
          return { 
            ...state 
          }
  
      case GET_VIDEOS_FOR_ADMIN_FAIL:
        return { 
          adminVideos: [],
          adminVideosLoading: false, 
          error: action.payload,
          index: action.index,
          ...state
        }


      case VIDEOS_LENGTH_FOR_FILTER_REQUEST:
        return { 
          adminVideosLoading: true, 
          ...state,
        }
  
      case VIDEOS_LENGTH_FOR_FILTER_SUCCESS:
        return { 
          ...state,
          videosLength: action.payload
        }
  
      case VIDEOS_LENGTH_FOR_FILTER_FAIL:
        return { 
          adminVideosLoading: false,
          ...state
        }


      case FILTER_RESULTS_FOR_ADMIN_REQUEST:
        return { 
          adminVideosLoading: true,
          index: action.index,
          ...state,
        }
  
      case FILTER_RESULTS_FOR_ADMIN_SUCCESS:
        return { 
          ...state,
          adminVideosLoading: false, 
          adminVideos: action.payload,
          index: action.index,
        }
  
      case FILTER_RESULTS_FOR_ADMIN_FAIL:
        return {
          adminVideos: [],
          adminVideosLoading: false, 
          error: action.payload,
          index: action.index,
          ...state,
        }


        case GET_VIDEO_OFF_REQUEST:
          return { 
            adminVideosLoading: true,
            index: action.index,
            ...state,
          }
    
        case GET_VIDEO_OFF_SUCCESS:
          return { 
            ...state,
            adminVideosLoading: false, 
            adminVideos: action.payload,
            index: action.index,
          }
    
        case GET_VIDEO_OFF_FAIL:
          return {
            adminVideos: [],
            adminVideosLoading: false, 
            error: action.payload,
            index: action.index,
            ...state,
          }


        case VIDEOS_LENGTH_FOR_SEARCH_REQUEST:
          return { 
            ...state,
            adminVideosLoading: true,
          }
    
        case VIDEOS_LENGTH_FOR_SEARCH_SUCCESS:
          return { 
            ...state,
            videosLength: action.payload,
          }
    
        case VIDEOS_LENGTH_FOR_SEARCH_FAIL:
          return { 
            ...state,
            adminVideosLoading: false,
          }

      case SEARCH_RESULTS_FOR_ADMIN_REQUEST:
        return { 
          ...state,
          adminVideosLoading: true,
          index: action.index
        }
  
      case SEARCH_RESULTS_FOR_ADMIN_SUCCESS:
        return { 
          ...state,
          adminVideosLoading: false, 
          adminVideos: action.payload,
          index: action.index,
        }
  
      case SEARCH_RESULTS_FOR_ADMIN_FAIL:
        return { 
          ...state,
          adminVideos: [],
          adminVideosLoading: false, 
          error: action.payload,
          index: action.index
        }
  
      default:
        return state
    }
  }


  export const videoInfoReducer = (state = { videoInfo: {} }, action) => {
    switch (action.type){
      case GET_VIDEO_INFO_REQUEST:
        return { loading: true,    videoInfo: {}}
  
      case GET_VIDEO_INFO_SUCCESS:
        return { loading: false, videoInfo: action.payload }
  
      case GET_VIDEO_INFO_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }
