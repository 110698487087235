import axios from 'axios'
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Notification from '../app-main-content/playlistAdds/Notification'

import {

    PLAYLIST_GROUPS_REQUEST,
    PLAYLIST_GROUPS_SUCCESS,
    PLAYLIST_GROUPS_FAIL,

    GROUP_PLAYLISTS_REQUEST,
    GROUP_PLAYLISTS_SUCCESS,
    GROUP_PLAYLISTS_FAIL,

    ALL_PLAYLISTS_REQUEST,
    ALL_PLAYLISTS_SUCCESS,
    ALL_PLAYLISTS_FAIL, 

    ADD_PLAYLIST_REQUEST,
    ADD_PLAYLIST_SUCCESS,
    ADD_PLAYLIST_FAIL,

    CHECK_VIDEO_IN_PLAYLIST_REQUEST,
    CHECK_VIDEO_IN_PLAYLIST_SUCCESS,
    CHECK_VIDEO_IN_PLAYLIST_FAIL,

    USER_PLAYLISTS_REQUEST,
    USER_PLAYLISTS_SUCCESS,
    USER_PLAYLISTS_FAIL,

    PLAYLIST_VIDEOS_REQUEST,
    PLAYLIST_VIDEOS_SUCCESS,
    PLAYLIST_VIDEOS_FAIL,

    ADD_VIDEO_TO_PLAYLIST_REQUEST,
    ADD_VIDEO_TO_PLAYLIST_SUCCESS,
    ADD_VIDEO_TO_PLAYLIST_FAIL,

    DELETE_VIDEO_FROM_PLAYLIST_REQUEST,
    DELETE_VIDEO_FROM_PLAYLIST_SUCCESS,
    DELETE_VIDEO_FROM_PLAYLIST_FAIL,

    DOWNLOAD_PLAYLIST_REQUEST,
    DOWNLOAD_PLAYLIST_SUCCESS,
    DOWNLOAD_PLAYLIST_FAIL,

    DELETE_PLAYLIST_REQUEST,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_FAIL,

    ARRANGE_VIDEOS_REQUEST,
    ARRANGE_VIDEOS_SUCCESS,
    ARRANGE_VIDEOS_FAIL,

    ADD_FULL_PLAYLIST_REQUEST,
    ADD_FULL_PLAYLIST_SUCCESS,
    ADD_FULL_PLAYLIST_FAIL, 

    TEMPORARY_PLAYLIST_REQUEST,
    TEMPORARY_PLAYLIST_SUCCESS,
    TEMPORARY_PLAYLIST_FAIL
} from '../constants/PlaylistConstants'


export const getPlaylistGroups = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLAYLIST_GROUPS_REQUEST
    })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuviewTV/get_groups/`,
      )

    dispatch({
      type: PLAYLIST_GROUPS_SUCCESS,
      payload: data
    })

  } catch(error) {
    dispatch({
      type: PLAYLIST_GROUPS_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}

export const getGroupPlaylists = (group_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GROUP_PLAYLISTS_REQUEST
    })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuviewTV/get_group_playlists/${group_id}/`,
      )

    dispatch({
      type: GROUP_PLAYLISTS_SUCCESS,
      payload: data,
      group_id: group_id
    })

  } catch(error) {
    dispatch({
      type: GROUP_PLAYLISTS_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}

export const getAllPlaylists = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_PLAYLISTS_REQUEST
    })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuviewTV/get_all_playlists/`,
      )

    dispatch({
      type: ALL_PLAYLISTS_SUCCESS,
      payload: data
    })

  } catch(error) {
    dispatch({
      type: ALL_PLAYLISTS_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}


export const listUserPlaylists = () => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }


    try {
      dispatch({
        type: USER_PLAYLISTS_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_user_playlists/`, config
        )
  
      dispatch({
        type: USER_PLAYLISTS_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: USER_PLAYLISTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

export const checkVideosInPlaylist = (video_id) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type: CHECK_VIDEO_IN_PLAYLIST_REQUEST
            })
    
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/cuviewTV/check_playlist/${video_id}/`,
                config
            )

            dispatch({
                type: CHECK_VIDEO_IN_PLAYLIST_SUCCESS,
                payload: data
            })
    
        } catch(error) {
            dispatch({
            type:CHECK_VIDEO_IN_PLAYLIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}

export const listPlaylistVideos = (playlist_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PLAYLIST_VIDEOS_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_playlist_videos/${playlist_id}/`,
        )

      dispatch({
        type: PLAYLIST_VIDEOS_SUCCESS,
        videos: data.videos,
        active_pl_id: data.id,
        active_pl_title: data.title,
        playlist_user_id : data.user_id
      })
  
    } catch(error) {
      dispatch({
        type: PLAYLIST_VIDEOS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }



  export const getTemporaryPlaylist = (video_id) => async (dispatch, getState) => {

    try {
      dispatch({
        type: TEMPORARY_PLAYLIST_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_custom_playlist/${video_id}/`,
        )
      
      dispatch({
        type: TEMPORARY_PLAYLIST_SUCCESS,
        videos: data.videos,
        active_pl_id: data.id,
        active_pl_title: data.title,
        playlist_user_id : data.user_id,
        group_id : data.group_id
      })
  
    } catch(error) {
      dispatch({
        type: TEMPORARY_PLAYLIST_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


export const addVideoToPlaylist = (video_list, playlist_id) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type: ADD_VIDEO_TO_PLAYLIST_REQUEST
            })
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/add_playlist_video/${playlist_id}/`,
            {'video_list': video_list},
                config
            )

            dispatch({
                type: ADD_VIDEO_TO_PLAYLIST_SUCCESS,
                payload: res.data,
                playlist_id : playlist_id,
                video_id : video_list[0]
            })

            Store.addNotification({
                content: <Notification message = {"Video has been added to playlist"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true
                }
                })
    
        } catch(error) {
            dispatch({
            type:ADD_VIDEO_TO_PLAYLIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}

export const deleteVideoFromPlaylist = (video_id, playlist_id, duration) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type: DELETE_VIDEO_FROM_PLAYLIST_REQUEST
            })
    
            const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist_video/${video_id}/${playlist_id}/`,
                config
            )

            dispatch({
                type: DELETE_VIDEO_FROM_PLAYLIST_SUCCESS,
                payload: data,
                deleted_playlist_id: playlist_id,
                deleted_video_id: video_id,
                video_duration: duration
            })

            Store.addNotification({
                content: <Notification message = {"Video has been deleted from playlist"}/>,
                type: 'custom',
                container: "bottom-left",
                insert: "top",
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true
                }
                })
    
        } catch(error) {
            dispatch({
            type:DELETE_VIDEO_FROM_PLAYLIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}


export const add_playlist = (playlist_title) => async (dispatch, getState) => {
    if (localStorage.getItem('access')){
        const config = {
            headers:{
                'Content-type':'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
              }
        }

        try {
            dispatch({
                type:ADD_PLAYLIST_REQUEST
            })
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/add_playlist/`,
                { 'title': playlist_title },
                config
            )
            
            dispatch({
                type:ADD_PLAYLIST_SUCCESS,
                payload: res.data
            })
    
        } catch(error) {
            dispatch({
            type:ADD_PLAYLIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
            })
        }

    }
}

export const download_playlist = (playlist_id, playlist_title) => async (dispatch, getState) => {
  if (localStorage.getItem('access')){
      const config = {
          headers:{
              'Authorization': `JWT ${localStorage.getItem('access')}`,
            },
            responseType: 'blob'
      }

      try {
          dispatch({
              type:DOWNLOAD_PLAYLIST_REQUEST
          })
  
          axios.get(`${process.env.REACT_APP_API_URL}/cuviewTV/download_playlist_info/${playlist_id}/`,
              config,
          ).then(
            function(res) {    
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `playlist_${playlist_title}.xlsx`);
              document.body.appendChild(link);
              link.click()    
              dispatch({
                type:DOWNLOAD_PLAYLIST_SUCCESS,
                payload: res.data
              })
            }
          )
  
      } catch(error) {
          dispatch({
          type:DOWNLOAD_PLAYLIST_FAIL,
          payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
          })
      }

  }
}

export const deletePlaylist = (playlist_id) => async (dispatch, getState) => {
  if (localStorage.getItem('access')){
      const config = {
          headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
            }
      }

      try {
          dispatch({
              type: DELETE_PLAYLIST_REQUEST
          })
  
          const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist/${playlist_id}/`,
              config
          )

          dispatch({
              type: DELETE_PLAYLIST_SUCCESS,
              videos: data.videos,
              active_pl_id: data.id,
              active_pl_title: data.title,
              playlist_user_id : data.user_id,
              deleted_id: playlist_id,
          })

          Store.addNotification({
              content: <Notification message = {"Playlist has been deleted"}/>,
              type: 'custom',
              container: "bottom-left",
              insert: "top",
              dismiss: {
                  duration: 3000,
                  pauseOnHover: true
              }
              })
  
      } catch(error) {
          dispatch({
          type:DELETE_PLAYLIST_FAIL,
          payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
          })
      }

  }
}

export const arrangeVideos = (playlist_id, pos1, pos2, index) => async (dispatch, getState) => {

  const config = {
    headers:{
        'Content-type':'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    }


  try {
    dispatch({
      type: ARRANGE_VIDEOS_REQUEST
    })
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cuviewTV/arrange_videos/${playlist_id}/${pos1}/${pos2}/${index}/`, config
      )

    dispatch({
      type: ARRANGE_VIDEOS_SUCCESS,
      payload: data,
      active_pl_id: playlist_id,
      index: index,
      pos1: pos1,
      pos2: pos2
    })

  } catch(error) {
    dispatch({
      type: ARRANGE_VIDEOS_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}

export const add_full_playlist = (playlist_title, video_list) => async (dispatch, getState) => {
  if (localStorage.getItem('access')){
      const config = {
          headers:{
              'Content-type':'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
            }
      }

      try {
          dispatch({
              type:ADD_FULL_PLAYLIST_REQUEST
          })
  
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/cuviewTV/add_full_playlist/`,
              { 'title': playlist_title, 'video_list': video_list },
              config
          )
          
          dispatch({
              type:ADD_FULL_PLAYLIST_SUCCESS,
              payload: res.data
          })

          Store.addNotification({
            content: <Notification message = {"Playlist has been added"}/>,
            type: 'custom',
            container: "bottom-left",
            insert: "top",
            dismiss: {
                duration: 3000,
                pauseOnHover: true
            }
            })
  
      } catch(error) {
          dispatch({
          type:ADD_FULL_PLAYLIST_FAIL,
          payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
          })
      }

  }
}