import React, {useEffect, useState, useRef}  from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import Back from '../adminIcons/Back.svg'
import { getTracksForAdmin } from '../../actions/AdminFilterActions';


const Tracks = (props) => {

    const { adminTracks, index, tracksLength } = useSelector(state=>state.adminTracks)

    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const handlePageClick = (event) => {
        setPage(event.selected+1)
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    useEffect(()=>{
        dispatch(getTracksForAdmin(props.album.id, page))
    },[page])

    const trackClickHandler = (track) => {
        console.log(track)
        props.setTrack(track)
        props.setComponent(4)
    }

    return (
        <>
            <div>
                <div className='d-flex pb-4' style={{gap:"1rem"}}>
                    <img 
                        src = {Back} 
                        role = "button"
                        alt = "" 
                        onClick={()=>props.setComponent(2)}/>
                    <div className = "fw-bold fs-7 text-success">ADD TRACK</div>
                    <div className = "fw-bold fs-7 text-secondary">STEP 3</div>
                    <div className = "fs-7 text-secondary">Choose track</div>
                </div>
                <div className = "d-flex bg-dark py-2 rounded-top align-items-center">
                    <div className="px-2 text-info"style={{width:"4%"}}>#</div>
                    <div className="px-2 text-info" style={{width:"25%"}}>
                        title
                    </div>
                    <div className="px-2 text-info" style={{width:"11%"}}>Genre</div>
                    <div className="px-2 text-info text-wrap" style={{width:"22%"}}>Composer</div>
                    <div className="px-2 text-info" style={{width:"24%"}}>Artists</div>
                    <div className="px-2 text-info" style={{width:"14%"}}>Duration</div>
                </div>
                {
                    adminTracks && adminTracks.length ? 
                    adminTracks.map((track, index)=>(
                        <div className = "d-flex bg-grey2 py-3 align-items-center border-bottom border-light"
                            key={index}
                            role="button"
                            onClick={()=>trackClickHandler(track)}>
                            <div className="px-3 text-dark"style={{width:"4%"}}>{track.position.toString().padStart(2,"0")}</div>
                            <div className="px-2 text-dark text-wrap" style={{width:"25%"}}>
                                {track.title}
                            </div>
                            <div className="px-2 text-primary text-wrap" style={{width:"11%"}}>{track.genre}</div>
                            <div className="px-2 text-primary text-wrap" style={{width:"22%"}}>{track.composers}</div>
                            <div className="px-2 text-primary text-wrap" style={{width:"24%"}}>{track.artists}</div>
                            <div className="px-2 text-primary" style={{width:"14%"}}>{toTimeString(track.duration)}</div>
                        </div>
                    )): ""
                }
                {
                    tracksLength &&  
                    <div className = 'd-flex justify-content-end bg-light'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={Math.ceil(tracksLength/10)}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed = {1}
                            containerClassName = "d-flex py-2 col-4 justify-content-end"
                            pageClassName = 'text-info fw-bold col-1 py-1 rounded bg-page border border-lighter list-unstyled m-1 text-center'
                            previousClassName = 'col-1 py-1 rounded bg-light list-unstyled m-1 text-center'
                            previousLinkClassName = 'text-secondary fw-bold col-1 py-1 rounded bg-light text-decoration-none m-1 text-center'
                            nextClassName = 'col-1 py-1 rounded bg-light list-unstyled m-1 text-center'
                            nextLinkClassName = 'text-secondary fw-bold col-1 py-1 rounded bg-light text-decoration-none m-1 text-center'
                            breakClassName = 'col-1 text-info list-unstyled d-flex align-items-end justify-content-center fs-2'
                            activeClassName = 'bg-success text-white fw-bold'
                        />
                    </div> 
                }
            </div>
        </>
    );
};

export default Tracks;