import React, {useState, useEffect, useRef} from 'react';
import PauseIcon from '../icons/Pause.svg'
import PrevIcon from '../icons/Prev.svg'
import NextIcon from '../icons/Next.svg'
import PlayIcon from '../icons/Play.svg'
import QRCode from "react-qr-code";
import Slider from '../slides/Slider'
import Minimize from '../icons/Minimize.svg'
import FullScreen from '../icons/FullScreen.svg'
import useWindowDimentions from '../useWindowDimentions'
import { useSelector } from 'react-redux';
import screenfull from "screenfull"
import ProgressSlider from './ProgressSlider';
import SoundIcon from '../icons/Sound.svg'
import MuteIcon from '../icons/Mute.svg'


const PlayerOverlay = (props) => {

    const { watermark_info } = useSelector(state=>state.analyze_info)
    const [full, setFull] = useState(false)
    const {width, height} = useWindowDimentions()
    const [qr, setQr] = useState(false)
    const [qualityVisible, setQualityVisible] = useState(false)
    const qualityRef = useRef()

    useEffect(()=>{
        const identifier = setTimeout(() => {
            setQr(!qr)
        }, 15000);
    
        return () => {
            clearTimeout(identifier)
        }
    
      },[qr])



    function exitHandler() {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            setFull(false)
        }
      }
    
    if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
    }


    const toggleFullScreen = (ref) => {
        if (props.playerContainerRef && props.playerContainerRef.current){
          screenfull.toggle(props.playerContainerRef.current)
          setFull(!full)
        }
    }

    const slideClickHandler = (v) => {
        props.setPlaying(true)
        props.setVideo(v)
        localStorage.setItem('video_id', v.id)
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:v.id})
      }
    

    const onChangeBitrate = (id) => {
        const internalPlayer = props.playerRef.current?.getInternalPlayer('hls');
        if (internalPlayer) {
            // currentLevel expect to receive an index of the levels array
            console.log(id)
            internalPlayer.currentLevel = id;
            props.setQuality(props.playerRef.current?.getInternalPlayer('hls')?.levels[id].height)
            setQualityVisible(false)
        }
    }


    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
          if (qualityRef == null || qualityRef.current == null || !qualityRef.current.contains(event.target)){
            setQualityVisible(false)
          }
        })
      })

    return (
        <div className='position-absolute w-100 h-100' style={{zIndex:100}} onClick={()=>props.setPlaying(!props.playing)}> 
            {
                width < 990 &&
                <div className='position-absolute col-4 d-flex justify-content-center align-items-center' 
                    style={{gap:"10px", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
                    <img src = {PrevIcon} alt = '' role = 'button' onClick={props.prevHandler}/>
                    {
                        props.playing ? 
                        <div role = "button" onClick={()=>props.setPlaying(false)}><img src = {PauseIcon} alt = ''/></div> :
                        <div role = "button" onClick={()=>props.setPlaying(true)}><img src = {PlayIcon} alt = ''/></div> 
                    }
                    <img src = {NextIcon} alt = '' role = 'button' onClick={props.endHandler}/>
                </div>
            }
            <div className='position-relative w-100 h-100 d-flex flex-column justify-content-between'>
                {
                    full && watermark_info && watermark_info.result_link && width > 990? 
                        <div className={
                            !props.playing || qr ? 
                            'rounded visible position-relative': 
                            'hidden'}
                            style={{ width:"256px", top:"30px", left:"30px", height: "auto", background:"white", padding:"0.7vw"}}
                        >
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={watermark_info.result_link}
                            viewBox={`0 0 256 256`}
                        />
                    </div> : <div></div>
                } 
                <div className='position-relative'>
                    <div className = 'w-100 position-relative bottom-0 overflow-hidden d-flex justify-content-end align-items-end m-0'
                        style={{height:"150px"}} 
                        >
                        {
                            full ? 
                            <div 
                                className={!props.playing || props.menuVisible ? 'col-12 px-4 visible position-absolute bottom-0' : ' col-12 px-4 position-absolute hidden mb-0'}
                                onClick={(e)=>e.stopPropagation()}>
                            <Slider
                                num = {6}
                                showTitle = {false}
                                type = {2}
                                tvData={props.playlistVideos}
                                slideClickHandler={slideClickHandler}
                            />
                            </div> : ""
                        } 
                        {
                            qualityVisible && 
                            <div 
                                ref = {qualityRef}
                                className = 'bg-secondary position-absolute d-flex flex-column align-items-center px-1 py-1 rounded me-2 mb-0' 
                                style={{zIndex:"200"}}
                                onClick={(e)=>e.stopPropagation()}
                                >
                                {props.playerRef.current?.getInternalPlayer('hls')?.levels.map(
                                    (level, id) => 
                                    <div className = 'fw-bold px-2 px-md-4 py-0 py-md-1 rounded quality-styles'
                                    style={props.quality === level.height ? {color:'white'} : {color:"#00C8D5"}}
                                        role='button'
                                        key={id} 
                                        onClick={()=>onChangeBitrate(id)}
                                        value={id}
                                        >
                                        {level.height + 'p'}
                                    </div>
                                )}
                            </div>
                        }

                    </div> 
                    <div className = 'position-relative w-100 bottom-0' onClick={(e)=>e.stopPropagation()}>
                        <div className = 'w-100 position-relative' >
                            <div className={!props.playing || props.menuVisible ? 'w-100 visible px-1': "hidden"}
                                style={{}}>
                                <ProgressSlider
                                    onChange = {props.onVideoChange}
                                    progress = {props.videoProgress}
                                    height = {'4px'}
                                />
                            </div>
                        </div>
                        <div className = {!props.playing || props.menuVisible ? 
                            'w-100 position-relative d-flex justify-content-between align-items-center px-3 pb-2 visible' 
                            : 'w-100 position-relative d-flex justify-content-between align-items-center px-3 pb-2 hidden' }
                            >
                            <div className = {'col-4 d-flex justify-content-start align-items-center'}>
                                {
                                    props.muted ? 
                                    <img src = {MuteIcon} alt = '' onClick={()=>props.setMuted(false)}/> :
                                    <img src = {SoundIcon} alt = '' onClick={()=>props.setMuted(true)}/>
                                }
                                <div className={'visible px-1'} style={{width:'100px'}}>
                                    <ProgressSlider
                                        onChange = {props.onSoundChange}
                                        progress = {props.soundProgress}
                                        height = {'2px'}
                                    />
                                </div>
                                <div className={'px-2 visible d-flex justify-content-between'} style={{width:"130px"}}>
                                    <div className='col-5 text-white text-start m-0'>
                                        {props.timeDisplay}
                                    </div>
                                    <div className='col-2 text-white px-1 '>
                                        |
                                    </div>
                                    <div className='col-5 text-white ps-1 text-start m-0'>
                                        {props.video && props.video.duration ? props.toTimeString(props.video.duration) : "00:00"}
                                    </div>
                                </div>
                            </div>
                            {
                                width > 990 &&
                                <div className='col-4 d-flex justify-content-center align-items-center' style={{gap:"10px"}}>
                                    <img src = {PrevIcon} alt = '' role = 'button' onClick={props.prevHandler}/>
                                    {
                                        props.playing ? 
                                        <div role = "button" onClick={()=>props.setPlaying(false)}><img src = {PauseIcon} alt = ''/></div> :
                                        <div role = "button" onClick={()=>props.setPlaying(true)}><img src = {PlayIcon} alt = ''/></div> 
                                    }
                                    <img src = {NextIcon} alt = '' role = 'button' onClick={props.endHandler}/>
                                </div>
                            }
                            <div className = 'col-4 d-flex align-items-center justify-content-end'>
                                <div className = 'px-2'>
                                    <div 
                                        onClick={()=>setQualityVisible(!qualityVisible)} 
                                        className = 'text-white px-2'
                                        role = 'button'
                                        style={{background:'transparent', appearance:"none", border:"none"}}>
                                            {props.quality + 'p'}
                                    </div>
                                </div>
                                {
                                    full ? 
                                    <div className={!props.playing || props.menuVisible ? 'visible' : ' hidden'}
                                        role = 'button'
                                        onClick={()=>toggleFullScreen(props.playerContainerRef)}
                                        >
                                            <img src = {Minimize} alt = ''/>
                                        </div> :
                                        <div className={!props.playing || props.menuVisible ? 'visible' : 'hidden'}
                                            role='button'
                                            onClick={()=>toggleFullScreen(props.playerContainerRef)}
                                        >   
                                        <img src = {FullScreen} alt = ''/>
                                    </div> 
                                }

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerOverlay;