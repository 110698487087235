import React, {useEffect, useState, useRef}  from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import PlaylistList from './PlaylistList';
import { getPlaylistSearchSuggestions, getPlaylistsLength, getplaylistsLengthForSearch, getPlaylistsSearchResults, getPlaylistsForAdmin } from '../../actions/AdminPlaylistsActions';
import search from '../adminIcons/search.svg'
import PlusIcon from '../adminIcons/PlusIcon.svg'
import { useNavigate } from 'react-router-dom'; 


const AdminPlaylists = () => {

    const playlistSearchRef = useRef()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [searchActive, setSearchActive] = useState(false)

    const [val, setVal] = useState("")

    const [page, setPage] = useState(1)

    const [searchValue, setSearchValue] = useState("")

    const { adminPlaylists, index, playlistsLength, loadingAdminPlaylists } = useSelector(state=>state.adminPlaylists)
      
    const {playlistSearchSuggestions} = useSelector(state=>state.playlistSearchSuggestions)

    const handlePageClick = (event) => {
        setPage(event.selected+1)
    }

console.log(loadingAdminPlaylists)

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (searchValue){
                dispatch(getPlaylistSearchSuggestions(searchValue))
                setSearchActive(true)
            } else {
                dispatch(getPlaylistSearchSuggestions(" "))
                setSearchActive(false)
            }
        }, 500)  
    
        return () => { clearTimeout(identifer) }
    
      },[val])

    useEffect(()=>{
        if(index){
            if (index === 1){
                dispatch(getPlaylistsForAdmin(page, 1))
            } else if (index === 3 && searchValue.length){
                dispatch(getPlaylistsSearchResults(searchValue, page, 3, 1))
            }
        } else {
            dispatch(getPlaylistsLength())
            dispatch(getPlaylistsForAdmin(1, 1))
        }

    },[page])


    const chooseHandler = (value) => {
        setSearchValue(value.title)
        executeSearch(value.title)
      }
  
    const handleKeyDown = (e) => {
        if(e.code === "Enter"){
            executeSearch(searchValue)
        }
    }

    const executeSearch = (value) => {
        if(value && value.length){
            setPage(1)
            dispatch(getplaylistsLengthForSearch(value))
            dispatch(getPlaylistsSearchResults(value, 1, 3, 1))
        }
        setSearchActive(false)
    }
  
      useEffect(()=>{
          document.addEventListener("mousedown", (event) => {
            if (playlistSearchRef == null || playlistSearchRef.current == null || !playlistSearchRef.current.contains(event.target)){
              setSearchActive(false)
            }
          })
        })

    return (
        <>
            <div className = 'px-5 py-5 col-10' style={{marginLeft:"15vw", minHeight:"100vh"}} >
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <div 
                        className = "fw-bold fs-7 text-info" 
                        role='button'
                        onClick={()=>{
                            setPage(1)
                            dispatch(getPlaylistsLength());
                            dispatch(getPlaylistsForAdmin(1,1))}} 
                    >PLAYLISTS</div>
                    <div className = "px-2 col-8 position-relative d-flex justify-content-end align-items-center" 
                        style={{userSelect:"none", height:"2.5rem"}} 
                        ref = {playlistSearchRef}
                        >
                        <div className="w-75 h-100 bg-success d-flex py-1 rounded border border-secondary"
                        >
                            <input
                                type="text"
                                className="col-11 fs-5 border-0 bg-success text-secondary search-input ps-3"
                                placeholder="Write or Select"
                                onKeyDown={handleKeyDown}
                                onChange={(e)=>{setSearchValue(e.target.value); setVal(e.target.value)}}
                                value={searchValue}
                            />
                            <div className='col-1 d-flex justify-content-end pe-2 bg-success'
                                onClick={()=> executeSearch(searchValue)}
                            >
                                <img src={search} alt="search icon" role= "button" className='p-2' />
                            </div>
                            {
                                searchActive && playlistSearchSuggestions && playlistSearchSuggestions.length ?
                                    <div className = "w-75 position-absolute mt-5 p-2 bg-white shadow rounded" 
                                    style = {{color:"#333", zIndex:1000}}>
                                        {
                                    playlistSearchSuggestions.map((result) => (
                                            <div
                                                className = "p-2 addSearchDropdown-item"
                                                role = "button"
                                                style={{transition:"all 0.2s"}}
                                                key = {result.id}
                                                onClick = {()=>chooseHandler(result)}
                                            >{result.title}</div>
                                        ))
                                        }
                                    </div> : ''
                                }
                                </div>
                    </div>
                    <div className='bg-secondary p-2 rounded-pill d-flex align-items-center' role='button'>
                        <img src = {PlusIcon} alt=""/>
                        <div 
                            className='text-white pe-3 ps-1 text-nowrap'
                            role = "button"
                            onClick={()=>navigate('/addPlaylist')}
                            >Create New Playlist</div>
                    </div>
                </div>
                {
                    loadingAdminPlaylists ? 
                    <div className='py-5 text-info fs-2'>Loading...</div> :  
                    <PlaylistList
                        playlists = {adminPlaylists}
                        index = {index}
                    />
                }
                {
                    playlistsLength &&  
                    <div className = 'd-flex justify-content-end bg-warning'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">>"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={Math.ceil(playlistsLength/10)}
                            previousLabel="<<"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed = {1}
                            containerClassName = "d-flex py-2 col-4 justify-content-end"
                            pageClassName = 'text-info col-1 py-1 rounded bg-success border border-secondary list-unstyled m-1 text-center'
                            previousClassName = 'text-info col-1 py-1 rounded bg-success border border-secondary list-unstyled m-1 text-center'
                            previousLinkClassName = 'text-info col-1 py-1 rounded bg-success text-decoration-none m-1 text-center'
                            nextClassName = 'text-info col-1 py-1 rounded bg-success border border-secondary list-unstyled m-1 text-center'
                            nextLinkClassName = 'text-info col-1 py-1 rounded bg-success text-decoration-none m-1 text-center'
                            breakClassName = 'col-1 text-info list-unstyled d-flex align-items-end justify-content-center fs-2'
                            activeClassName = 'bg-info text-white'
                        />
                    </div> 
                }
            </div>
        </>
    );
};

export default AdminPlaylists;