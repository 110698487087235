import React, {useState} from 'react';
import SelectIcon from '../adminIcons/Select.svg'
import SelectedIcon from '../adminIcons/Selected.svg'
import DeleteIcon from '../adminIcons/DeleteIcon.svg'
import EditIcon from '../adminIcons/EditIcon.svg'
import PlusIcon from '../adminIcons/PlusIcon.svg'
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddToPlaylist from './AddToPlaylist';
import { useDispatch } from 'react-redux';
import { deleteVideo } from '../../actions/videoActions';
import { useNavigate } from 'react-router-dom';


const VideoComponent = (props) => {

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const [addActive, setAddActive] = useState(false)

    const [showFull, setShowFull] = useState(false)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }
    

    const get_titles = (lst) => {
        let t = []
        for (let i=0; i<lst.length; i++){
            t.push(lst[i].title)
        }
        const title = t.join(', ')
        return title
    }

    const checkHandler = () => {
        if (props.videoList.includes(props.video.id)){
            props.setVideoList(prevArr => prevArr.filter(i => i!==props.video.id))
        } else {
            props.setVideoList((prevArr)=>{ return[...prevArr, props.video.id]})
        }
    }

    const handleDelete = () => {
        var answer = window.confirm("Do you really want to delete the Video?");
        if (answer) {
            dispatch(deleteVideo(props.video.id, props.video.video_bunny_id))
        }
    }

    return (
        <div className={props.videoList.includes(props.video.id) ? 'w-100 bg-secondary d-flex align-items-center px-3 py-1 border-top border-secondary' : 'w-100 bg-success d-flex align-items-center px-3 py-1 border-top border-secondary'}>
            {
                <div style = {{width:'3%'}}
                    role = 'button'
                    onClick={()=>checkHandler()}
                >
                    <img src = {props.videoList.includes(props.video.id) ? SelectedIcon: SelectIcon}/>
                </div>
            }
            <div className = 'mx-2' style = {{width:'10%'}}>
                <img
                    className = 'w-100 p-1' 
                    src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=300&qq=${Math.random()}`}
                    style={{aspectRatio:"14/9"}}/>
            </div>
            <div className = 'text-white mx-2 overflow-hidden' style = {{width:'23%'}}>{props.video.title}</div>
            <div className = 'text-white mx-2 overflow-hidden' style = {{width:'10%'}}>{get_titles(props.video.genres)}</div>
            <div className = 'text-white mx-2 overflow-hidden' style = {{width:'11%'}}>{get_titles(props.video.moods)}</div>
            <div className = 'text-white mx-2 overflow-hidden text-center' style = {{width:'9%'}}>{toTimeString(props.video.duration)}</div>
            {/* {
            } */}
            <div>

            </div>
            <div className = 'text-white mx-2 overflow-hidden' style = {{width:'26%'}}>
                {showFull ? get_titles(props.video.tags): get_titles(props.video.tags).slice(0,135)}
                {
                    get_titles(props.video.tags).length > 135 ?
                    <span 
                        className = 'text-info  text-nowrap' 
                        role = 'button' 
                        style={{zIndex:"100"}}
                        onClick={()=>setShowFull(!showFull)}>{showFull ? ' Show Less' : ' ...Show more'}</span> :""
                }
            </div>
            <div className = 'text-white mx-2 d-flex justify-content-around' style = {{width:'8%'}}>
                <img 
                    src = {EditIcon} 
                    role = 'button'
                    onClick={()=>navigate(`/editVideo/${props.video.id}`)}
                    alt = "" />
                <img 
                    src = {PlusIcon} 
                    role = "button"
                    onClick={addClickHandler}
                    alt = "" />
                <img 
                    src = {DeleteIcon} 
                    role = 'button'
                    onClick={()=>handleDelete()}
                    alt = "" 
                />
            </div>
            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {top}
                right = {right}
                content = {
                    <AddToPlaylist
                        setIsActive = {setAddActive}
                        video = {props.video}
                        pl = {0}
                    /> 
                }
            /> 
        </div>
    );
};

export default VideoComponent;