import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import BackIcon from '../adminIcons/BackIcon.svg'
import { getPlaylistInfo, editPlaylistForAdmin } from '../../actions/AdminPlaylistsActions';
import { useParams, useNavigate } from 'react-router-dom'
import { editPlaylistThumbnail } from '../../actions/AdminPlaylistsActions';
import VideoItem from './VideoItem';
import {
    DndContext, 
    closestCorners,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
  } from "@dnd-kit/sortable";


const EditAdminPlaylist = (props) => {

    const [imageFile, setImageFile] = useState()
    const [imageURL, setImageUrl] = useState()
    const [statusOn, setStatusOn] = useState(0)
    const [title, setTitle] = useState("")
    const [items, setItems] = useState([])

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {playlist_id} = useParams()

    const {loadingInfo, playlistInfo} = useSelector(state=>state.playlistInfo)

    useEffect(()=>{
        dispatch(getPlaylistInfo(playlist_id))
    },[])

    useEffect(()=>{
        if(playlistInfo){
            setTitle(playlistInfo.title)
            setStatusOn(playlistInfo.status)
        }
    },[playlistInfo])

    useEffect(()=>{
        if(playlistInfo && playlistInfo.videos && playlistInfo.videos.length){
            setItems(playlistInfo.videos)
        }
    },[playlistInfo])

    const handleImage = (e) => {
        setImageFile(e.target.files[0])
        let u = URL.createObjectURL(e.target.files[0])
        setImageUrl(u)
        dispatch(editPlaylistThumbnail(playlistInfo.id, e.target.files[0]))
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
        }),
        useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
        }),
        useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        props.setChanges(true)
        const {active, over} = event;
        if (active.id !== over.id) {
          setItems((items) => {
            const oldIndex = items.map(item=>item.position).indexOf(active.id);
            const newIndex = items.map(item=>item.position).indexOf(over.id);
            if (active.id>over.id){
                items.forEach(item => {
                    if(item.position < active.id && item.position >= over.id){
                        item.position += 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } else if (active.id<over.id){
                items.forEach(item => {
                    if(item.position > active.id && item.position <= over.id){
                        item.position -= 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } 
            return arrayMove(items, oldIndex, newIndex);
          });
        }
    }

    const editPlaylistHandler = () => {
        if(!title.length){
            alert('Please, Provide Title')
        } else {
            let positions = []
            if (items && items.length){
                items.forEach(item=>positions.push({id: item.id, position: item.position})) 
            }
            dispatch(editPlaylistForAdmin(playlist_id, title, statusOn, positions ))
            navigate(-1)
            props.setChanges(false)
        }
    }

    const backHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }

    
    return (

        <div className = "col-10 p-4" style={{marginLeft:"18rem", minHeight:"100vh"}}>   
            {
                loadingInfo ? <>loading...</> : playlistInfo ? 
                <> 
                    <div className='d-flex align-items-center pb-4'>
                        <img 
                            src = {BackIcon} 
                            role = 'button'
                            onClick={backHandler}
                            alt = ""/>
                        <div className = "fw-bold fs-7 ps-4 text-secondary" 
                            role='button' 
                        >{playlistInfo.title}</div>
                    </div>
                    <div className = "w-100 d-flex pb-5">
                        <div className = "col-7 bg-success me-3 rounded px-3 py-5">
                            <div className = "text-secondary fw-bold pb-2">Title*</div>
                            <input
                                type="text"
                                className="col-12 rounded fs-5 border-0 bg-warning text-white py-3 search-input ps-3"
                                onChange = {(e)=>{setTitle(e.target.value); props.setChanges(true)}}
                                value = {title}
                            />
                            <div className = "text-secondary fw-bold pt-4 pb-2">Videos</div>
                            <DndContext 
                                    sensors={sensors}
                                    collisionDetection={closestCorners}
                                    onDragEnd={handleDragEnd}
                                    >
                                <div className = "bg-warning w-100 rounded addToplaylist-list-wrapper" style = {{maxHeight:"500px"}}>
                                    {
                                        items && items.length ?
                                        <SortableContext items = {items.map(video=>video.position)} strategy={verticalListSortingStrategy}>
                                            {
                                                items.map((video, index)=>(
                                                    <VideoItem
                                                        playlist_id = {playlistInfo.id}
                                                        video = {video}
                                                        key = {video.id}
                                                        changes = {props.changes}
                                                        setChanges = {props.setChanges}
                                                    />
                                                ))
                                            }
                                        </SortableContext> : ""
                                    }
                                </div>  
                            </DndContext>
                            <div className = "py-4" role = "button">
                                {
                                    statusOn === 0 ? 
                                    <div className = "d-flex align-items-center">
                                        <img src = {SwitchOff} alt = "" onClick={()=>{setStatusOn(1); props.setChanges(true)}}/>
                                        <div className = "text-info ms-4">Access OFF</div>
                                    </div>
                                     : 
                                     <div className = "d-flex align-items-center">
                                        <img src = {SwitchOn} alt = "" onClick={()=>{setStatusOn(0); props.setChanges(true)}}/>
                                        <div className = "text-info ms-4">Access ON</div>
                                    </div>
                                }
                            </div>
                            <div className = "w-100 d-flex justify-content-end">
                                <div 
                                    className = "text-white px-5 py-2 bg-secondary rounded-pill"
                                    role = "button"
                                    onClick = {()=>editPlaylistHandler()}
                                >Save</div>
                            </div>
                        </div>
                        <div className = "col-3 bg-success rounded p-2">
                            <div className = "bg-warning p-2 mt-3 rounded">
                                <div className = "text-info pb-2">Upload Thumbnail</div>
                                <>
                                    <img src = {imageFile && imageFile.name ? imageURL: `https://img.cugate.com/?o=CUTV_PLAYLIST&i=${playlistInfo.id}&s=300`} alt = ""
                                        style = {{width:"100%", aspectRatio:"16/9"}}
                                    />
                                    <div className = "w-100 d-flex justify-content-between align-items-center mt-3">
                                        {
                                            imageFile && imageFile.name ?                                             <div className = "text-info w-75 text-decoration-underline text-wrap overflow-hidden">{imageFile.name}</div> : ""
                                        }
                                        <input 
                                            id = "reimage-input"
                                            type="file" 
                                            name="imageFile"
                                            accept='image/jpeg, image/png, image/jpg'
                                            onChange={handleImage}
                                            style={{display:"none"}}
                                            />
                                            <label for="reimage-input">
                                                <div 
                                                    className = "text-info py-2 px-3 ms-2 bg-success rounded"
                                                    role='button'
                                                >Change</div>
                                            </label>
                                    </div>
                                </> 
                            </div>
                        </div>
                    </div>
                </> : 
                <div className='w-100 h-100 d-flex justify-content-center align-items-center text-info fw-bold fs-4'>
                    There's Some Error, please, try again later
                </div>
            }
        </div>
    );
};

export default EditAdminPlaylist;