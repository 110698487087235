import React from 'react';
import Export from '../icons/Export.svg'
import deleteIcon from '../icons/delete.svg'
import { download_playlist } from '../../actions/PlaylistActions'
import { deletePlaylist } from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'
import replay from '../icons/rePlay.svg'

const PlayerHeader = (props) => {

    const {user} = useSelector(state=>state.user)

    const dispatch = useDispatch()

    const { playlistVideos, active_pl_id, playlist_user_id, active_playlist_title} = useSelector(state => state.userPlaylists)

    const handleDelete = () => {
        if(window.confirm('Delete Playlist?')){
            dispatch(deletePlaylist(active_pl_id))
        }
    }

    const autoPlayHandler = () => {
        props.setVideo(playlistVideos[0])
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:playlistVideos[0].id})
        localStorage.setItem('video_id', playlistVideos[0].id)
    }

    return (

        <div className = 'col-12 col-lg-11 py-3 d-flex flex-column flex-md-row px-1 justify-content-end'
        >
            {
                active_playlist_title && active_playlist_title.length ? 
                <div className = "col-12 col-md-8 col-lg-8 text-info fw-bold fs-7 overflow-hidden " 
                    title={active_playlist_title} 
                    style = {{whiteSpace:"nowrap"}}>{active_playlist_title.toUpperCase()}
                </div> :""
            }

            <div className='col-12 col-md-4 ps-md-0 col-lg-3 d-flex justify-content-between align-items-center'>
                <div className = "">
                        {
                            playlistVideos && playlistVideos.length &&
                            <div className = "text-info fs-3"   
                                style={{fontWeight:"700"}}
                                >{`${playlistVideos.length} Videos`}
                            </div>
                        }
                </div>
                <div className='d-flex pe-3' style={{gap:"1.5rem"}}>
                    {
                      playlistVideos && playlistVideos.length && 
                      <>
                        <img src = {Export}  
                            role = "button"
                            style={{width:"1rem"}}
                            onClick={()=>dispatch(download_playlist(active_pl_id, active_playlist_title))}/>
                        <img 
                            src ={replay}
                            onClick={()=>autoPlayHandler()}
                            role='button'
                            style={{width:"1rem"}} />
                        </>
                    }
                    { user.id === playlist_user_id ?
                        <img src = {deleteIcon} 
                        role = "button"
                        style={{width:"1rem"}}s
                            onClick={()=>handleDelete()} 
                        /> : ""
                    }
                </div>
            </div>
        </div>
    );
}

export default PlayerHeader;
